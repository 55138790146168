<template>
  <eva-layout column scroll class="eva-map-layers-filter">
    <eva-checkbox
      v-model="isShowAll"
      :label="$eva.$t('$t.eva-map-layers-filter.showAll')"
      @input="showAll()"
    />
    <div class="eva-border-bottom"></div>
    <eva-checkbox
      v-for="(item, index) in ListLayers"
      :key="$eva.$tools.uuid()"
      v-model="item.isVisible"
      :label="$eva.$t(item.ref_layer.name)"
      @input="changeVisibleLayer(item, $event)"
    />
  </eva-layout>
</template>

<script>
import { ref } from 'vue';

export default {
  name: "eva-map-layers-filter",

  props: {
    state: {
      type: Object,
    },
  },
  
  data() {
    return {
      isShowAll: ref(true),
    }
  },

  watch: {
    ListLayers: {
      handler() {
        this.isShowAll = !this.ListLayers.some(layer => !layer.isVisible)
      },
      immediate: true,
    }
  },
  
  methods: {
    changeVisibleLayer(item, value) {
      if (!item.isVisible) {
        this.$set(this, 'isShowAll', false)
      }
      let LayerId = null;
      for (const layer of this.state.layers) {
        for (const object of layer.mapObjects) {
          LayerId = object.mapObject.ref_layer?.id ? object.mapObject.ref_layer.id: null;
          if (LayerId === item.ref_layer?.id) {
            this.$eva.$storages.local.set(`VISIBLE:${this.state.mapSourceId}_${item.ref_layer.id}`, value);
            this.$set(object, 'isVisibleMapObject', value);
            if (!value) {
              this.$set(object, 'isSelected', false);
            }
          }
        }
      }
    },
    showAll() {
      let LayerId = null;
      for (const layer of this.state.layers) {
        for (const object of layer.mapObjects) {
          LayerId = object.mapObject.ref_layer.id ? object.mapObject.ref_layer.id : null;
          this.$eva.$storages.local.set(`VISIBLE:${this.state.mapSourceId}_${LayerId}`, this.isShowAll);
          this.$set(object, 'isVisibleMapObject', this.isShowAll);
        }
      }
      this.$nextTick(() => { 
        this.ListLayers.map(layer => this.$set(layer, 'isVisible', this.isShowAll));
    }) 
    }
  },
  computed: {
    ListLayers() {     
      return this.state.mapObjectLayers;
    },
  },
};
</script>

<style lang="less">
.eva-map-layers-filter {
  max-height: 380px;
  padding: @eva-padding * 2;
}
</style>

<locale lang="ru">
{
  showAll: 'Показать все'
}
</locale>
