<template>
  <eva-table
    :settings="settings"
    v-model="state.selectedLayer"
    class="eva-map-layers-list"/>
</template>

<script>
export default {
  name: 'eva-map-layers-list',

  props: {
    box: {
      type: Object
    },
    selectedObjects: {
      type: Array,
      default: [],
    },
    state: {
      type: Object
    },
  },

  data() {
    return {
      types: [],
      settings: {
        prefix: this.$options.name,
        caption: false,

        location: false,
        selectable: true,
        type: 'drawer',

        columns: {
          name: {
            type: 'text',
            validators: [
              'require'
            ],
            sort: 'ASC'
          },
          sort_order: {
            type: 'number',
            showInRepeater: false
          }
        },

        url: '/api/v1/mapsservice/maplayers',

        commands: {
          edit: {
            visible: true
          },
          custom: [
            {
              name: "clearLayer",
              type: 'icon--flat--small',
              icon: "mdi-layers-off",
              handle: () => this.clearLayer(),
            }
          ]
        }
      }
    }
  },

  watch: {
    "state.selectedLayer": {
      async handler(value) {
        if (value && this.selectedObjects.length > 0) {
          if (await this.$eva.$boxes.confirm({
            message: this.$eva.$t(`$t.${this.$options.name}.commands.addLayer.confirm`)
          })) {
            this.selectedObjects[0].mapObject.ref_layer = this.state.selectedLayer;
            try {
              await this.$eva.$http.put(
                `/api/v1/mapsservice/mapobject/${this.selectedObjects[0].mapObject.id}`, 
                this.selectedObjects[0].mapObject
              );
              await this.$eva.$boxes.notifySuccess(this.$eva.$t(`$t.${this.$options.name}.commands.addLayer.success`));
            } catch(err) {
              console.error(err)
              await this.$eva.$boxes.notifyError(this.$eva.$t(`$t.${this.$options.name}.commands.addLayer.error`));
            }         
            this.box.hide(this.state.selectedLayer);
          }
        } else if (value) {
          this.$eva.$storages.local.set(`LAST_SELECTED_LAYER:${this.state.mapSourceId}`, value);
          this.box.hide(value);
        }
      },
    }
  },

  methods: {
    async clearLayer() {
      if (this.selectedObjects.length > 0) {
        if (await this.$eva.$boxes.confirm({
          message: this.$eva.$t(`$t.${this.$options.name}.commands.clearLayer.confirm`)
        })) {
          this.selectedObjects[0].mapObject.ref_layer = null;
          try {
            await this.$eva.$http.put(
              `/api/v1/mapsservice/mapobject/${this.selectedObjects[0].mapObject.id}`, 
              this.selectedObjects[0].mapObject
            );
            await this.$eva.$boxes.notifySuccess(this.$eva.$t(`$t.${this.$options.name}.commands.clearLayer.success`));
          } catch(err) {
            console.error(err)
            await this.$eva.$boxes.notifyError(this.$eva.$t(`$t.${this.$options.name}.commands.clearLayer.error`));
          }         
          this.box.hide(null);
        }
      } else {
        this.$eva.$storages.local.set(`LAST_SELECTED_LAYER:${this.state.mapSourceId}`, null);
        this.box.hide(null);
      }
    },
  },

  async mounted() {
    let { items } = await this.$eva.$http.getList('/api/v1/mapsservice/maplayers');
    this.types = items;
  }
}
</script>

<style lang="less">
.eva-map-layers-list {
  max-height: 420px;
  max-width: 580px;
}
</style>

<locale lang="ru">
{
  caption: 'Слои',
  columns: {
    name: {
      header: 'Название'
    },
    sort_order: {
      header: 'Сортировка'
    },
  },
  commands: {
    add: {
      header: 'Создать слой',
      success: 'Слой создан успешно',
      error: 'При создании слоя произошла ошибка'
    },
    edit: {
      header: 'Редактировать слой',
      success: 'Слой отредактирован успешно',
      error: 'При редактировании слоя произошла ошибка'
    },
    remove: {
      header: 'Удалить слой',
      confirm: 'Удалить слой?',
      success: 'Слой удален успешно',
      error: 'При удалении слоя произошла ошибка'
    },
    clearLayer: {
      header: 'Сбросить слой',
      success: 'У выбранных объектов слой успешно сброшен',
      error: 'При сбросе слоя у выбранных объектов произошла ошибка',
      confirm: 'Сбросить слой у выбранных объектов?'
    },
    addLayer: {
      success: 'Выбранным объектам успешно назначен слой',
      error: 'При назначении слоя выбранным объектам произошла ошибка',
      confirm: 'Назначить выбранным объектам слой?'
    }
  }
}
</locale>
