<template>
  <div
      class="eva-input eva-textbox"
      :class="classes"
      @click.stop.prevent="onClick($event)"
  >
    <label v-if="label" class="eva-textbox__label eva-foreground-2">
      {{ label }}
    </label>

    <label v-if="sublabel" class="eva-textbox__label eva-foreground-2" :style="sublabelColor && `color: ${sublabelColor}!important;`">
      {{ sublabel }}
    </label>

    <slot name="header"></slot>

    <div class="eva-textbox__inner align-center">

      <div
        class="eva-textbox__background"
        :class="getClass"
      >
        <span v-if="!value">{{ placeholder }}</span>
      </div>

      <div class="eva-textbox__input d-flex align-center">
        <slot/>
      </div>

      <div class="eva-textbox__buttons">
        <div
          v-if="icon"
          class="eva-textbox__button mdi" :class="icon"
          @click.stop.prevent="onIconClick($event)"
          @mousedown.stop.prevent
          v-tooltip="$eva.$tools.tooltipContent(currentTooltip)"
        >
        </div>
        <div
          v-if="!!error"
          v-tooltip="$eva.$tools.tooltipContent(error, 'error')"
          class="eva-textbox__button mdi mdi-alert-circle eva-textbox__button--error"
        ></div>
        <div
          v-if="!!info"
          v-tooltip="$eva.$tools.tooltipContent(info, 'primary')"
          class="eva-textbox__button mdi mdi-help-circle eva-textbox__button--primary"
        ></div>
        <div
          v-if="clearable"
          class="eva-textbox__button mdi mdi-close"
          @click.stop.prevent="clearValue"
          @mousedown.stop.prevent
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'eva-input',

  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    sublabel: {
      type: String
    },
    sublabelColor: {
      type: String
    },
    placeholder: {
      type: String
    },
    icon: {
      type: String
    },
    iconlabel: {
      type: String
    },
    clearable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: false,
    },
    depth: {
      type: Number,
    },
    info: {
      type: String
    },
    error: {
      type: String
    }
  },

  computed: {
    classes() {
      return {
        'eva-textbox--error': !!this.error,
        'eva-textbox--readonly': !!this.readonly,
        'eva-textbox--preview': !!this.preview,
      }
    },
    currentTooltip() {
      return (this.icon && this.iconlabel)
        ? this.iconlabel
        : null;
    },
    getClass() {
      return `eva-background-${this.depth}`;
    }
  },

  methods: {
    clearValue() {
      this.$emit('clear', true);
    },
    onClick(e) {
      this.$emit('click', e);
    },
    onIconClick(e) {
      this.$emit('icon-click', e);
    }
  }
}
</script>

<style lang="less">
.eva-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0;
  /*height: 56px;*/
  &.eva-textbox--readonly {
    .eva-textbox__inner {
      opacity: 0.6;
    }
  }
  > .eva-textbox__inner {
    flex-grow: 1;
    min-height: 40px;
    padding: 1px 12px;
  }
  > .eva-textbox__label {
    margin-bottom: 0;
  }
  &.eva-textbox--preview {
    cursor: default !important;
    & .eva-textbox__inner {
      padding: 0 !important;
      .eva-textbox__background {
        background-color: transparent !important;
      }
      .eva-textbox__input {
        cursor: default !important;
      }
    }
  }
  .eva-textbox__button--error {
    color: #CC1D26;
    &:hover {
      color: #E23244;
    }
  }
  .eva-textbox__button--primary {
    color: #0260CF;
    &:hover {
      color: #006CEB;
    }
  }
  .eva-textbox__background {
    /*color: #3E4C5D;*/
    font-size: 14px;
    font-family: inherit;
    padding: 1px 12px;
    line-height: 26px;
    opacity: 0.8;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
</style>
