<template>
  <eva-repeater
    :settings="settings"
    :value="selected"
    :value-alt="valueAlt"
    :filter-values="filter"
    :no-padding="noPadding"
    :expanding="toggleOverflow"
    @input="$emit('input', $event)"
    @item-added="$emit('item-added', $event)"
    @item-edited="$emit('item-edited', $event)"
    @item-removed="$emit('item-removed', $event)"
    @change-filter="$emit('change-filter', $event)"
    @changeSettings="changeSettings"
    class="eva-table"
    :class="{
      'eva-table_overflow': toggleOverflow,
    }"
    ref="repeater"
  >
    <template v-slot:subheader>
      <slot name="subheader"/>
    </template>

    <template v-slot:context>
      <slot name="context"/>
    </template>

    <template v-slot:quick-filter="{ options }">
      <slot name="quick-filter" v-bind="{ options }"/>
    </template>

    <template v-slot:items="{ options, items }">
      <table>
        <colgroup>
          <col
            v-for="column in columns"
            v-if="column.name !== settings.groupBy"
            :key="column.name"
            :width="column.width"
          >
          <col :width="getCommandsCellWidth(options)">
        </colgroup>
        <thead>
        <tr v-if="showTableHeader">
          <th
            v-for="column in columns" :key="column.name"
            v-if="column.name !== settings.groupBy"
            style="font-size: 12px; color: #ACBBCB"
            :class="getColumnClass"
          >
            <div
                class="eva-table__header"
                :class="{ 'eva-table__header--sortable': column.sort !== false }"
            >
              <div class="eva-table__header_label eva-text-clipped" @click="toggleSort(options, column)" v-tooltip="$eva.$tools.tooltipContent($eva.$t(column.label))">
                {{ $eva.$t(column.label) }}
              </div>
              <div v-if="column.sort && settings.sorting !== 'disabled'" class="eva-table__header_sort" @click="toggleSort(options, column)">
                <eva-icon
                  :icon="column.sort === 'ASC' ? 'mdi-arrow-up-thin' : 'mdi-arrow-down-thin'"
                />
                <span v-if="settings.sorting === 'multiple'" class="eva-table__header_sort_number">
                  {{ column.sortPosition }}
                </span>
              </div>
              <slot name="filter" v-bind="{ options, column }"></slot>
            </div>
          </th>
          <th
            v-if="options.itemCommands
              && options.itemCommands.length"
            :class="getColumnClass"
          ></th>
        </tr>
        </thead>
        <tbody>
        <template
            v-for="(item, itemIndex) in items"
        >
          <tr
            v-if="!!settings.groupBy && (itemIndex === 0 || getGroupByName(items[itemIndex - 1], settings.groupBy) !== getGroupByName(items[itemIndex], settings.groupBy))"
            :key="`${itemIndex}_groupBy`"
          >
            <td :colspan="columns.length" class="eva-background-3 eva-border-bottom">
              {{ groupByColumn }}: {{ getGroupByName(items[itemIndex], settings.groupBy) }}
            </td>
          </tr>
          <tr
            :draggable="!!state.drag"
            :key="item ? item.id : itemIndex"
            :id="item ? item.id : itemIndex"
            @mousedown.left.stop="onRowClick(options, item)"
            @dragstart="onDragStart($event, item)"
            :class="getRowClass(options, item)"
            class="eva-background-2 eva-background-hoverable"
          >
            <td
              v-for="column in columns"
              v-if="column.name !== settings.groupBy"
              :key="(item ? item.id : itemIndex) + column.name"
              class="eva-border-bottom"
            >
              <slot v-if="item" :name="`item.${column.name}`" :item="item">
                <component
                  v-if="column.component"
                  :is="column.component"
                  :model="item"
                  v-bind="column.componentProps"
                />
              </slot>
            </td>
            <td v-if="options.itemCommands && options.itemCommands.length" class="eva-border-bottom">
              <eva-command-list
                type="icon--flat"
                :commands="options.itemCommands"
                :model="item"
              />
            </td>
          </tr>
        </template>
        </tbody>
      </table>
      <div style="height: 2px">
        <eva-intersect
          v-if="options.state.initialized && !options.state.loading"
          @enter="onEnterScroll"
        />
      </div>

    </template>

  </eva-repeater>
</template>

<script>
import { reactive, computed, ref } from "vue";
import moment from 'moment';

import EvaDefaultTableCell from "./cells/EvaDefaultTableCell";
import EvaPathTableCell from "./cells/EvaPathTableCell";
import EvaIconTextTableCell from "./cells/EvaIconTextTableCell";
import EvaChipListCell from "./cells/EvaChipListCell";
import EvaCheckboxCell from "./cells/EvaCheckboxCell";
import EvaDateTableCell from "./cells/EvaDateTableCell";

function createState(app, emit, options) {
  const settings = ref(null);

  if (options) {
    settings.value = options;
  }

  return Object.seal(reactive({
    drag: computed(() => settings.value && settings.value.drag),
    settings
  }));
}

export default {
  name: 'eva-table',

  props: {
    settings: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Object
    },
    valueAlt: {
      type: Object
    },
    filter: {
      type: Object,
    },
    noAltColor: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selected: null,
      columns: [],
      sorting: [],
      state: createState(this.$eva, (...args) => this.$emit(...args)),
      toggleOverflow: ref(false),
      desktopId: this.$route?.path,
    };
  },

  computed: {
    showTableHeader() {
      return this.settings && this.settings.tableHeader !== false
    },
    groupByColumn() {
      return this.settings && this.settings.metadataReport.params.locales[this.$locale.current].columns[this.settings.groupBy].header;
    },
    getColumnClass() {
      return `eva-background-${
        Number.isFinite(this.settings.depth) ? this.settings.depth : 2}`;
    },
    CURRENT_TAB_ID() {
      return this.$eva.$storages.local.get('CURRENT_TAB_ID');
    }
  },

  watch: {
    settings: {
      handler(value) {
        this.state.settings = value;
      },
      deep: true,
      immediate: true
    },
    'settings.columns': {
      handler() {
        this.columns = this.getColumns();
      },
      deep: true,
      immediate: true
    },
    CURRENT_TAB_ID: {
      handler(value) {
        const expanding = this.$eva.$storages.local.get(`EXPANDING_${this.desktopId}_${value}`);
        if (expanding) {
          this.toggleOverflow = expanding;
        }
      },
      immediate: true
    }
  },

  methods: {
    changeSettings(overflow) {
      this.toggleOverflow = overflow;
      const writeLocal = this.settings.footerSettings?.writeLocal === undefined
        ? true
        : this.settings.footerSettings.writeLocal;
      if (this.desktopId && writeLocal) {
        this.$eva.$storages.local.set(`EXPANDING_${this.desktopId}_${this.CURRENT_TAB_ID}`, overflow);
      }
    },
    getRowClass(options, item) {
      let classes = {
        'eva-table__row--clickable': !!options.clickCommand,
        'eva-table__row--selected' : item.id === (options.selectedItem && options.selectedItem.id) && !Number.isFinite(this.settings.depth),
        'eva-table__row--selected-alt' : item.id === (options.selectedAltItem && options.selectedAltItem.id),
        'eva-background-2': this.noAltColor,
        'eva-background-1': !this.noAltColor && Number.isFinite(this.settings.depth),
        'eva-background-active': item.id === (options.selectedItem && options.selectedItem.id)
                                  && !this.noAltColor && Number.isFinite(this.settings.depth),
      };

      let { rowClass } = this.settings;
      if (typeof rowClass === 'function') {
        rowClass = rowClass(item);
      }
      if (typeof rowClass === 'string' && !!rowClass) {
        classes[rowClass] = true;
      }

      return classes;
    },
    getColumns() {
      let result = this.$eva.$tools.mapObjectOrArray(this.settings.columns, (column) => {
        if (column.showInRepeater === false) {
          return null;
        }

        let result = {
          label: `$t.${this.settings.prefix}.columns.${column.name}.header`,
          name: column.name,
          name_in_sort: column.name_in_sort,
          type: column.type,
          utc: column.utc,
          width: column.width || '1*',
          sort: column.sort,
          path: column.path,
          join: column.join,
          color: column.color,
          icon: column.icon,
          text: column.text,
          filter: column.filter,
          clipped: column.clipped,
          align: 'start',
        };

        let props = {};

        if (column.chips) {
          result.component = EvaChipListCell;
        } else if (column.icon) {
          result.component = EvaIconTextTableCell;
        } else if (column.path) {
          result.component = EvaPathTableCell;
        } else if (column.type === 'checkbox') {
          result.component = EvaCheckboxCell;
          result.checkbox_value = column.checkbox_value;
        } else if (column.type === 'date') {
          result.component = EvaDateTableCell;
          props.format = column.format || 'DD.MM.YYYY';
          props.utc = column.utc;
        } else if (column.type === 'datetime') {
          result.component = EvaDateTableCell;
          props.format = column.format
            ? column.format
            : column.minutes
              ? 'DD.MM.YYYY HH:mm'
              : 'DD.MM.YYYY HH:mm:ss';
          props.utc = column.utc;
        } else if (column.type === 'time') {
          result.component = EvaDateTableCell;
          props.format = column.format
            ? column.format
            : column.minutes
              ? 'HH:mm'
              : 'HH:mm:ss';
          props.utc = column.utc;
        } else {
          result.component = EvaDefaultTableCell;
        }

        if (result.component) {
          result.componentProps = {
            column: result,
            ...props
          }
        }
        return result;
      });

      this.normalizeWidths(result);
      this.normalizeSorting(result);

      if (this.settings.sorting === 'multiple') {
        if (this.sorting.length) {
          const sortColumns = [];
          for (let i = 0; i < this.sorting.length; i++) {
            this.sorting[i].sortPosition = i + 1;
            sortColumns[i] = this.sorting[i].sort === 'DESC'
                ? `${this.sorting[i].name_in_sort || this.sorting[i].name}%-1`
                : `${this.sorting[i].name_in_sort || this.sorting[i].name}%1`
          }
          let sortRow = sortColumns.join(',');
          this.$nextTick(() => {
            this.$refs.repeater.options.filter.sorting = sortRow;
          });
        }
      } else {
        this.$nextTick(() => {
          if (this.sorting.length) {
            this.$refs.repeater.options.filter.sorting = this.sorting[0].sort === 'DESC'
                ? `${this.sorting[0].name_in_sort || this.sorting[0].name}%-1`
                : `${this.sorting[0].name_in_sort || this.sorting[0].name}%1`;
          }
        });
      }
      return result;
    },
    normalizeSorting(columns) {
      this.sorting = [];
      if (this.settings.sorting === 'multiple') {
        let sortColumns = columns.filter(c => !!c.sort);

        if (sortColumns.length) {
          columns.forEach(column => {
            if (column.sort) {
              column.sort = (column.sort || '').toString().toUpperCase();
              if (column.sort !== 'DESC') {
                column.sort = 'ASC';
              }
            } else if (column.sort !== false) {
              column.sort = null;
            }
          });
        } else {
          const column = columns.find(c => c.sort !== false);
          column.sort = 'ASC';
          sortColumns.push(column);
        }

        this.sorting = sortColumns;
        return sortColumns;
      } else {
        let sortColumn = columns.find(c => !!c.sort);
        if (!sortColumn) {
          sortColumn = columns.find(c => c.sort !== false);
        }
        columns.forEach(column => {
          if (column === sortColumn) {
            column.sort = (column.sort || '').toString().toUpperCase();
            if (column.sort !== 'DESC') {
              column.sort = 'ASC';
            }
          } else if (column.sort !== false) {
            column.sort = null;
          }
        });
        if (sortColumn) {
          this.sorting.push(sortColumn);
        }
        return sortColumn;
      }
    },
    normalizeWidths(columns) {
      if (this.settings.groupBy) {
        columns = columns.filter(c => c.name !== this.settings.groupBy);
      }
      let count = 0;
      for (let i = 0; i < columns.length; i++) {
        if (columns[i].width.endsWith('*')) {
          count += parseInt(columns[i].width.substring(0, columns[i].width.length - 1));
        }
      }
      if (count) {
        for (let i = 0; i < columns.length; i++) {
          if (columns[i].width.endsWith('*')) {
            let value = parseInt(columns[i].width.substring(0, columns[i].width.length - 1));
            columns[i].width = `${100 / count * value}%`;
          }
        }
      }
    },
    toggleSort(options, column) {
      if (this.settings.sorting !== 'disabled') {
        if (this.settings.sorting === 'multiple') {
          if (column.sort === false) {
            return;
          }

          if (column.sort === 'ASC') {
            column.sort = 'DESC';
          } else if (column.sort === 'DESC') {
            column.sort = null;
            this.sorting = this.sorting.filter(c => c.name !== column.name);
          } else {
            column.sort = 'ASC';
            this.sorting.push(column);
          }

          if (this.sorting.length) {
            const sortColumns = [];
            for (let i = 0; i < this.sorting.length; i++) {
              let name = this.sorting[i].name_in_sort || this.sorting[i].name;
              this.sorting[i].sortPosition = i + 1;
              sortColumns[i] = this.sorting[i].sort === 'DESC'
                  ? `${name}%-1`
                  : `${name}%1`
            }
            let sortRow = sortColumns.join(',');
            this.$nextTick(() => {
              this.$refs.repeater.options.filter.sorting = sortRow;
            });
          } else {
            options.filter.sorting = column.sort;
          }
        } else {
          if (column.sort === false) {
            return;
          }
          this.sorting = [];
          if (column.sort) {
            column.sort = column.sort === 'DESC' ? 'ASC' : 'DESC';
          } else {
            let old = this.columns.find(c => !!c.sort);
            if (old) {
              old.sort = null;
            }
            column.sort = 'ASC';
          }
          this.sorting.push(column);
          let name = column.name_in_sort || column.name;
          options.filter.sorting = column.sort === 'DESC'
              ? `${name}%-1`
              : `${name}%1`;
        }
      }
    },
    getCommandsCellWidth(options) {
      if (!options.itemCommands || !options.itemCommands.length) {
        return '0px';
      }

      let count = options.itemCommands.filter((c) => c.visible !== false).length;
      let result =
          count * this.$eva.$styles.evaIconSize.value +       //размер иконок
          (count - 1) * this.$eva.$styles.evaPadding.value +  //размер расстояний между иконками
          2 * this.$eva.$styles.evaPadding.value;             //поддинг ячейки таблицы

      return this.$eva.$styles.evaIconSize.formatValue(result);
    },
    onRowClick(options, item) {
      if (options.clickCommand && this.settings.selectable !== false) {
        options.clickCommand.execute(item);
      }
      if (this.settings?.selectedSimple) {
        //простое выделение строки
        options.selectedItem = { ...item };
      }
    },
    getGroupByName(item, groupBy) {
      if (!item || !groupBy) {
        return '';
      }
      let column = this.columns.find((c) => c.name === groupBy);
      if (!column) {
        return '';
      }
      let result = item[groupBy];
      if (column.type === 'date' || column.type === 'datetime' || column.type === 'time') {
        if (!column.format) {
          if (column.type === 'date') {
            column.format = 'DD.MM.YYYY';
          } else if (column.type === 'datetime') {
            column.format = 'DD.MM.YYYY HH:mm';
          } else if (column.type === 'time') {
            column.format = 'HH:mm:ss';
          }
        }
        if (item[groupBy] !== 0 && item[groupBy] != null) {
          result = column.utc
              ? moment.utc(item[groupBy], 'x').format(column.format || 'DD.MM.YYYY')
              : moment(item[groupBy], 'x').format(column.format || 'DD.MM.YYYY');
        } else {
          result = '';
        }
      }
      if (Array.isArray(result)) {
        result = result.join(', ');
      }
      return this.$eva.$tools.getNestedValue(result, column.path);
    },
    reload() {
      return this.$refs.repeater.reload();
    },
    onDragStart(e, item) {
      if (!this.state.drag) {
        return;
      }
      this.$eva.$dragndrops.beginDragMove(e, this.state.drag, item);
    },
    onEnterScroll() {
      this.$refs.repeater.options.loadNext();
    }
  }
};
</script>

<style lang="less">
.eva-table_overflow {
  overflow-x: auto;
  width: 100%;
  min-width: 100%;
  
  .eva-repeater__content {
    table {
      width: auto !important;
    }

    .eva-default-table-cell {
      max-width: 300px;
    }
  }
}

.eva-table {
  height: 100%;
  &.eva-table--clickable {

  }
  &.eva-repeater--no-padding {
    table {
      padding-left: 0;
      padding-right: 0;
    }
  }

  table {
    width: 100%;
    min-width: 100%;
    border: none;
    border-spacing: 0;
    border-collapse: separate;
    table-layout: fixed;
    /*padding-left: @eva-padding;
    padding-right: @eva-padding;*/

    tr {
      border: none;
      /*color: #3E4C5D;*/

      &.eva-table__row--event {
        /*background-color: #F6F8FF;*/
      }

      &.eva-table__row--odd {

      }

      &.eva-table__row--clickable:hover {
        /*background-color: #E1F5FE;*/
        cursor: pointer;
      }

      &.eva-table__row--selected {
        background-color: #0260CF !important;
        color: white!important;
        .eva-btn {
          &:not(.eva-btn--secondary) {
            color: white !important;
          }
        }
      }

      &.eva-table__row--selected-alt {
        td {
          border: 3px solid #0260CF !important;
        }
        color: white!important;
        .eva-btn {
          &:not(.eva-btn--secondary) {
            color: white !important;
          }
        }
      }
    }

    th {
      text-align: left;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      height: 42px;
      border: none;
      /*background-color: white;*/
      white-space: nowrap;
      z-index: 1;
      font-weight: 400;
      font-size: 1.2rem;
    }

    td {
      font-weight: 400;
    }

    td, th {
      text-align: left;
      padding: (@eva-padding / 4) @eva-padding;
      height: 32px;
      white-space: normal;
      word-break: break-word;
      font-size: 14px;
    }

    td:first-child, th:first-child {
      padding-left: (@eva-padding * 2);
    }

    td:last-child, th:last-child {
      padding-right: (@eva-padding * 2);
    }

    tr:last-child {
      td, th {
        border-bottom: none!important;
      }
    }

    .eva-table__header {
      display: flex;
      align-items: center;
      user-select: none;
      min-height: 24px;
      opacity: 0.7;

      &.eva-table__header--sortable {
        cursor: pointer;
        opacity: 1;
      }

      .eva-table__header_label {

      }

      .eva-table__header_sort {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .eva-table__header_sort_number {
        margin: auto;
        width: 18px;
        height: 18px;
        text-align: center;
        background-color: rgba(0,0,0,.12);
        border-radius: 50%;
      }
    }
  }
}
</style>
