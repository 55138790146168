<template>
  <eva-map-tools-panel 
    top left 
    class="eva-map-layers-panel" 
    >
    <div 
      class="eva-layers-block"
      :class="{ 'layer-not-selected': !state.selectedLayer }"
      @click="openLayersList"
      >
      <eva-icon
        :icon="'mdi-layers'"
        :size="21"
        class="eva-layer-icon"
      />
      <a 
        class="eva-text-clipped"
      >
        {{ hasSelectedLayer }}
      </a>
    </div>
  </eva-map-tools-panel>
</template>

<script>
import EvaMapToolsPanel from "./EvaMapToolsPanel";
import EvaMapLayersList from "./EvaMapLayersList.vue";

export default {
  name: 'eva-map-layers-panel',

  components: {
    EvaMapToolsPanel,
  },

  props: {
    state: {
      type: Object
    },
  },

  methods: {
    async openLayersList(event) {
      let layer = await this.$eva.$boxes.show({
        type: 'dropdown',
        activator: event.target,
        anchor: 'bottom-left',
        header: 'Добавление слоя',
        closeOnCLick: true,
        component: EvaMapLayersList,
        componentProps: {
          state: this.state,
          selectedObjects: this.selectedObjects,
        }
      });
      if (layer !== 'cancel' && typeof layer === 'object') {
        this.state.selectedLayer = layer;
      }
    }
  },

  mounted() {
    this.state.selectedLayer = this.$eva.$storages.local.get(`LAST_SELECTED_LAYER:${this.state.mapSourceId}`);
  },

  watch: {
    selectedObjects: {
      handler() {
        if (this.selectedObjects.length > 1) {
          const refLayer = this.selectedObjects[0].mapObject.ref_layer;
          const allIdsAreEqual = this.selectedObjects.every(obj => obj.mapObject.ref_layer.id === refLayer.id);
          this.state.selectedLayer = allIdsAreEqual ? refLayer : { name: '' };
        } else {
          this.state.selectedLayer = this.selectedObjects.length > 0 && this.selectedObjects[0]?.mapObject?.ref_layer?.id
            ? this.selectedObjects[0].mapObject.ref_layer
            : null;
        }
      },
      deep: true,
    },
    "state.selectedLayer": {
      handler(value) {
        if (this.selectedObjects.length === 0 && !value) {
          this.state.selectedLayer = this.$eva.$storages.local.get(`LAST_SELECTED_LAYER:${this.state.mapSourceId}`);
        }
      }
    }
  },

  computed: {
    hasSelectedLayer() {
      return this.state.selectedLayer 
        ? this.state.selectedLayer.name 
        : this.$eva.$t(`$t.${this.$options.name}.notSelected.header`); 
    },
    selectedObjects() {     
      let selectedObjects = [];
      for (const objectId of this.state.selectedMapObjects) {
        const objects = this.state.findObjectsByMapObject(objectId);
        for (const obj of objects) {
          if (obj.isSelected && !selectedObjects.some(object => object.id === obj.id)) {
            selectedObjects.push(obj);
          }
        }
      }
      return selectedObjects;
    },
  },
}
</script>

<style lang="less">
  .eva-map-layers-panel {
    left: 175px !important;
    .eva-map-tools-panel__inner {
      padding: 6px;
    }
    .eva-layers-block {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .eva-text-clipped {
        min-width: 80px;
      }

      &.layer-not-selected {
        a, .eva-layer-icon {
          opacity: 0.5;
        }
      }
    }
    
    &:hover {
      a {
        opacity: 1 !important;
      }
    }
  }
</style>

<locale lang="ru">
{
  notSelected: {
    header: 'Не выбрано'
  },
}
</locale>