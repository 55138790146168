<template>
  <div class="eva-file-field" v-show="!isHide">
    <div v-if="!this.readOnly" class="eva-file-field__header">
      <eva-btn type="icon--secondary" icon="mdi-paperclip" @click="add()" />
      <eva-text
        :text="fieldLabel || 'прикрепить документ'"
        color="#0260CF"
        @click="add"
      />
    </div>
    <div v-if="modelValue" class="eva-file-field__items">
      <eva-chip
        v-for="(item, index) in modelValue"
        :key="index"
        :label="item.full_name || `${item.name}${item.extension || ''}`"
        :commands="commands"
        :tag="item"
        @click.stop.prent="download(item)"
      />
    </div>
    <input
      ref="uploader"
      name="file"
      type="file"
      multiple
      class="eva-display-none"
      @change="upload($event.target.files)"
    />
  </div>
</template>

<script>
export default {
  name: "eva-file-field",

  dbType: "ref",

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    commands() {
      let result = [];
      if (!this.readOnly && !this.preview) {
        result.push({
          icon: "mdi-close",
          handler: (item) => this.remove(item),
        });
      }
      return result;
    },
  },

  methods: {
    async add() {
      if (this.readOnly || !this.loading || !this.preview) {
        this.$refs.uploader.click();
      }
    },

    async upload(items) {
      if (!this.modelValue) {
        this.modelValue = [];
      }
      try {
        this.loading = true;
        for (let i = 0; i < items.length; i++) {
          if (items[i] instanceof File) {
            const data = new FormData();
            data.append("file", items[i]);
            data.append(
              "data",
              JSON.stringify({
                name: items[i].name,
              })
            );
            // console.log("upload-", this.settings.folder);
            // console.log("data", {
            //   file: items[i],
            //   data: JSON.stringify({
            //     name: items[i].name,
            //   }),
            // });
            let res = await this.$eva.$http.post(
              `/api/v1/fileservice/fileobject?folder=${this.settings.folder}`,
              data
            );
            //console.log("res", res);
            this.modelValue.push(res);
          }
        }
      } catch (error) {
        this.$eva.$boxes.notifyError(
          "Во время загрузки файлов произошла ошибка"
        );
        this.$eva.$logs.error(
          this.$options.name,
          "Во время загрузки файлов произошла ошибка",
          error
        );
      } finally {
        this.loading = false;
        this.$refs.uploader.value = null;
      }
    },

    async remove(item) {
      if (this.readOnly || !this.modelValue || this.loading) {
        return;
      }

      if (
        await this.$eva.$boxes.confirm({
          message: `Удалить файл ${
            item.full_name || item.name + item.extension
          } ?`,
        })
      ) {
        let index = this.modelValue.indexOf(item);
        this.modelValue.splice(index, 1);
      }
    },

    async download(item) {
      if (this.loading) {
        return;
      }
      try {
        const link = document.createElement("a");
        link.download = item.full_name;
        link.target = "_blank";
        link.href = `/api/v1/fileservice/file/${item.id}`;
        link.setAttribute("download", item.full_name || item.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        this.$eva.$logs.error(
          this.$options.name,
          "При скачивании файла произошла ошибка",
          error
        );
      }
    },
  },
};
</script>

<style lang="less">
.eva-file-field {
  display: flex;
  flex-direction: column;
  gap: 4px 8px;
  border-top: 2px solid #e5f1ff;
  border-bottom: 2px solid #e5f1ff;
  padding: 16px 0;

  .eva-file-field__header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    .eva-text {
      cursor: pointer;
    }
  }

  .eva-file-field__items {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}
</style>
