import evaApp from "@eva/client/app";

const LICENSES = {
  intellect: "ESM-Intellect",
  securos: "ESM-SecurOS",
};
async function checkLicense(videoStreamId) {
  try {
    let result = false;
    if (videoStreamId) {
      const videoStream = await evaApp.$http.get(
        `/api/v1/videohubservice/videostream/${videoStreamId}`
      );
      const provider = videoStream?.provider;
      const License = LICENSES[provider];
      result = License
        ? evaApp.$licenses.checkLicenses(License)
        : false;
    }
    return result;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export default checkLicense;
