<template>
  <div
    v-show="!isHide"
    class="eva-image-field"
    :style="styles"
    @click="onClick"
  >

    <label v-if="fieldLabel" class="eva-textbox__label eva-foreground-2">
      {{ fieldLabel }}
    </label>

    <eva-image
      :src="currentUrl"
      :alt="fieldLabel"
      :error="hasErrors"
      :messages="fieldErrors"
      :disabled="readOnly"
    >
      <eva-command-list
        v-if="!readOnly && hasValue"
        :commands="commands"
        class="eva-image-field__commands"
      />
    </eva-image>

    <input
      v-if="!readOnly"
      ref="uploader"
      name="file"
      type="file"
      class="eva-display-none"
      accept="image/*"
    >
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'eva-image-field',

  dbType: 'ref',

  data() {
    return {
      commands: [{
        prefix: `${this.$options.name}.commands`,
        name: 'remove',
        type: 'icon--flat',
        handle: () => this.remove()
      }],
      fileStringBase64: ref(null),
    }
  },

  computed: {
    styles() {
      let result = this.paddingStyles;
      if (this.settings && this.settings.size) {
        result.height = `${this.settings.size}px`;
      }
      return result;
    },

    currentUrl() {
      let value = this.modelValue;

      if (this.fileStringBase64) {
        return this.fileStringBase64;
      }

      if (typeof value === 'string') {
        return value;
      }
      if (value && value.id) {
        return `/api/v1/fileservice/file/${value.id}`;
      } else {
        return '/assets/images/no_image.png';
      }
    },
    hasValue() {
      let value = this.modelValue;
      return value && value.id;
    },
    internalSave() {
      return typeof this.settings?.internalSave === 'boolean'
        ? this.settings?.internalSave
        : true; 
    }
  },

  methods: {
    async onClick(e) {
      if (this.readOnly) {
        return;
      }

      let res = await new Promise((resolve, reject) => {
        let file = null;
        const onChange = (e) => {
          file = e.target.files && e.target.files[0]
          this.$refs.uploader.value = null;
          clear();
          resolve(file);
        }
        const onFocus = (e) => {
          setTimeout(() => {
            if (!file) {
              clear();
              resolve(null);
            }
          }, 1000);
        }
        const clear = () => {
          this.$refs.uploader.removeEventListener('change', onChange);
          window.removeEventListener('focus', onFocus);
        }
        this.$refs.uploader.addEventListener('change', onChange, { once: true });
        window.addEventListener('focus', onFocus, { once: true });
        this.$refs.uploader.click();
      });
      await this.upload(res);
    },

    async upload(file) {
      if (!file) {
        return;
      }
      if (this.internalSave) {
        try {
          if (!file.type || file.type.toLowerCase().indexOf('image') !== 0) {
            return this.$eva.$boxes.notifyError('Файл не является изображением');
          }
          const data = new FormData();
          data.append('file', file);
          this.modelValue = await this.$eva.$http.post(`/api/v1/fileservice/fileobject?folder=platform/custom_icons`, data);
        } catch (error) {
          this.$eva.$boxes.notifyError('Во время загрузки изображения произошла ошибка');
          this.$eva.$logs.error(
              this.$options.name,
              'Во время загрузки изображения произошла ошибка',
              error
          );
        } finally {
          this.$refs.uploader.value = null;
        }
      } else {
        this.$nextTick(() => {
          const fileReader = new FileReader();
          fileReader.onloadend = () => {
            this.fileStringBase64 = fileReader.result;
          }
          fileReader.readAsDataURL(file);
          this.modelValue = file;
        });
      }
    },

    async remove() {
      let id = this.modelValue && this.modelValue.id;
      if (id) {
        this.modelValue = null;
      }
    }
  }
}
</script>

<style lang="less">
.eva-image-field {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  .eva-image {
    opacity: 0.8;
    border-radius: 6px;
    position: relative;
    img {
      border-radius: 6px;
    }
    .eva-image-field__commands {
      position: absolute;
      right: 4px;
      top: 4px;
      .eva-btn {
        width: 32px;
        min-width: 32px;
        height: 32px;
        flex-shrink: 0;
        background-color: #2A3746;
        color: #E4E9EF;
        border-radius: 6px;
        opacity: 0.7;
        padding: 4px;
        align-items: center;
      }
    }
  }
}
</style>

<locale lang="ru">
{
  commands: {
    remove: {
      header: 'Удалить изображение',
      confirm: 'Удалить изображение ?',
      success: 'Изображение удалено успешно',
      error: 'При удалении изображения произошла ошибка'
    }
  }
}
</locale>
