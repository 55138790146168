
    import localesHash from '@eva/client/plugins/locales/LocalesHash';
    export default function (Component) {
      localesHash.push({ 
        lang: 'ru', 
        data: {
          [Component.options.name]: 
{
  commands: {
    showLayers: {
      header: 'Слои'
    },
    showFilter: {
      header: 'Фильтр'
    },
    showMapsourceViews: {
      header: 'Быстрые переходы'
    },
    showAlarmSign: {
      header: 'Признак тревоги'
    },
  }
}

        } 
      });
    }