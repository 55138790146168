<template>
  <div class="d-flex">
    <button
      class="eva-btn-commands"
      :class="buttonClasses"
      :disabled="isDisable"
      :style="buttonStyles"
      v-tooltip="$eva.$tools.tooltipContent(currentTooltip)"
      @click.stop.prevent="onClick($event)"
      @mousedown.stop.prevent
    >
      <span v-if="hasIcon" class="eva-btn-commands__icon mdi" :class="currentIcon"></span>
      <span v-if="!isIcon">{{ currentLabel }}</span>
      <span v-if="showBadge" class="eva-btn-commands--badge"></span>
    </button>
    <button
      class="eva-btn-commands-list"
      :class="buttonCommandClasses"
      @click.stop.prevent="onCommandClick($event)"
    >
      <eva-icon
        icon="mdi-chevron-down"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'eva-btn-commands',

  props: {
    type: {
      type: String
    },
    label: {
      type: String | Number
    },
    icon: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    badge: {
      type: Boolean
    },
    color: {
      type: String
    },
    command: {
      type: Object
    }
  },

  computed: {
    currentType() {
      let type = this.getProperty('type');
      if (type) {
        let parts = type.split('--');
        return parts[0];
      }
      let switchType = (this.getProperty('icon') ? '1' : '0') + (this.getProperty('label') ? '1' : '0');
      switch (switchType) {
        case '10':
          return 'icon';
        case '11':
          return 'icon-text';
        case '01':
          return 'text';
        default:
          return 'icon';
      }
    },
    isIcon() {
      return this.currentType === 'icon';
    },
    hasIcon() {
      return this.currentType && this.currentType.indexOf('icon') >= 0;
    },
    currentIcon() {
      return this.getProperty('icon');
    },
    currentLabel() {
      return this.$eva.$t(this.getProperty('label'));
    },
    buttonClasses() {
      let [ ,...modifiers] = (this.getProperty('type') || '').split('--');
      return modifiers.map((m) => `eva-btn-commands--${m}`).join(' ');
    },
    buttonCommandClasses() {
      let [ ,...modifiers] = (this.getProperty('type') || '').split('--');
      return modifiers.map((m) => `eva-btn-commands-list--${m}`).join(' ');
    },
    buttonStyles() {
      return {
        color: this.getProperty('color')
      };
    },
    isDisable() {
      return this.getProperty('disabled') === true;
    },
    showBadge() {
      return this.getProperty('badge') === true;
    },
    currentTooltip() {
      return (this.isIcon && this.currentLabel)
        ? this.currentLabel
        : null;
    }
  },

  methods: {
    getProperty(name) {
      if (this.command && this.command[name] != null) {
        return this.command[name];
      }
      return this[name];
    },
    onClick(e) {
      if (!this.isDisable && !this.disabled && !this.command?.disabled) {
        this.$emit('click', e);
      }
    },
    async onCommandClick(e) {
      if (!this.isDisable && !this.disabled && !this.command?.disabled) {
        this.$emit('click:command', e);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.eva-btn-commands {
  position: relative;
  margin-right: 1px;
  .eva-btn-commands--badge {
    position: absolute;
    border-radius: 50%;
    background-color: #0260CF;
    /*background-color: #0260CF;*/
    top: 4px;
    right: 4px;
    width: 10px;
    height: 10px;
  }
  &[disabled] {
    .eva-btn-commands--badge {
      background-color: #0260CF;
      /*background-color: #F7F8FF;*/
    }
  }
}
</style>
