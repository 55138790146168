<template>
  <div
      class="eva-date-table-cell"
      :class="{ 'eva-text-clipped': isClipped }"
  >
    <span v-tooltip="$eva.$tools.tooltipContent(currentDateValue)">
      {{ currentDateValue }}
    </span>
  </div>
</template>

<script>
import EvaTableCellMixin from "./EvaTableCellMixin";
import moment from 'moment';

export default {
  name: 'eva-date-table-cell',

  mixins: [
    EvaTableCellMixin
  ],

  props: {
    format: {
      type: String
    },
    utc: {
      type: Boolean
    },
    zeroIsNull: {
      type: Boolean,
      default: true,
    }
  },

  computed: {
    currentDateValue() {
      let result = this.currentValue;

      if (this.zeroIsNull && typeof result === 'number' && result <= 0) {
        return '';
      }

      let momentDate = this.utc 
        ? moment.utc(result, 'x') 
        : moment(result, 'x');
      if (!momentDate.isValid() || typeof result === 'string' && isNaN(Number(result))) {
        return '-';
        // switch(this.format) {
        //   case 'HH:mm:ss':
        //     return 'Invalid time';
        //   case 'DD.MM.YYYY HH:mm:ss':
        //     return 'Invalid datetime';
        //   default:
        //     return 'Invalid date';
        // }
      }
      return momentDate.format(this.format);
    }
  }
}
</script>

<style lang="less">
.eva-date-table-cell {

}
</style>
