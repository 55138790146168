<template>
  <div :class="col?.class" :style="col?.style">
    <span class="eva-textbox__label eva-foreground-3"> {{ col.label }} </span>
    <span class="eva-textbox__input .eva-description" :class="col?.descriptionColor">
      {{ datetime }}
    </span>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'dateTime',

  props: {
    col: {
      type: Object,
      default: () => { },
    },
  },
  /* 
  example
  
  col1: {
    type: 'dateTime',
    label: 'Начало',
    description: 1719467813288
  }
  
  */
  computed: {
    datetime() {
      //на входе дата формата 1727944989057
      if(this.col.description === 0) return '-';
      
      if (this.col?.mask) {
        const formatDatetime = (dateString, mask) => {
          const datetime = new Date(dateString);
          const dt = moment(datetime).format(mask); ;//moment(datetime).utc().format(mask); //moment(datetime, mask).valueOf();

          return dt
        }
        const maskDate = formatDatetime(this.col.description, this.col?.mask);

        return maskDate;
      }
      const datetime = new Date(this.col.description);
      let outText = datetime.toLocaleString();
      if (outText === 'Invalid Date') outText = '-';
      return outText;
    },



  }

}
</script>

<style lang="less" scoped>
.etv-rowLabel {
  color: #fff
}

.eva-label {
  color: #7B8B9D;
}

.eva-description {
  color: #E4E9EF;
}

.green-1 {
  color: #3cb17e;
}
</style>