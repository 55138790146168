<template>
  <eva-map-tools-panel top left :commands="commands"/>
</template>

<script>
import EvaMapToolsPanel from "./EvaMapToolsPanel";
import EvaMapFilter from "./EvaMapFilter";
import EvaMapSourceViewList from "./EvaMapSourceViewList";
import EvaMapLayersFilter from "./EvaMapLayersFilter.vue";
import { ref } from "vue";

const MAP_SHOW_NAMES = 'MAP_SHOW_NAMES';

export default {
  name: 'eva-map-common-panel',

  components: {
    EvaMapToolsPanel
  },

  props: {
    state: {
      type: Object
    },
    hideMapsourceViews: {
      type: Boolean,
      default: false
    }
  },

  data(){
    return {
      showAlarm: ref(false),
      commands: this.$eva.$commands.createList([
        {
          prefix: `${this.$options.name}.commands`,
          name: "showFilter",
          icon: "mdi-filter-variant",
          handle: (model, event) => this.showFilter(event),
        },
        {
          prefix: `${this.$options.name}.commands`,
          name: "showLayers",
          icon: "mdi-layers",
          handle: (model, event) => this.showLayers(event),
        },
        this.hideMapsourceViews ? null : {
          prefix: `${this.$options.name}.commands`,
          name: 'showMapsourceViews',
          icon: 'mdi-map-marker-multiple',
          handle: (model, event) => this.showMapsourceViews(event)
        },
        {
          prefix: `${this.$options.name}.commands`,
          name: "showAlarmSign",
          icon: () =>
            this.showAlarm ? "mdi-alarm-light" : "mdi-alarm-light-outline",
          handle: (model, event) => this.showAlarmSign(event),
        },
      ].filter((c) => !!c))
    }
  },

  watch: {
    'state.mapSourceId': {
      handler(value) {
        const noMapSource = !value;
        this.showAlarm = this.$eva.$storages.local.get(`SHOW_ONLY_ALARM:${value}`);
        this.state.showOnlyAlarm = this.showAlarm;
        this.commands[0].disabled = noMapSource;
        this.commands[1].disabled = noMapSource;
        this.commands[3].disabled = noMapSource;
      },
      immediate: true
    }
  },

  methods: {
    async showLayers(event) {
      await this.$eva.$boxes.show({
        type: "dropdown",
        activator: event.target,
        anchor: "bottom-left",
        header: "Слои",
        closeOnCLick: true,
        component: EvaMapLayersFilter,
        componentProps: {
          state: this.state,
        },
      });
    },
    async showFilter(event) {
      await this.$eva.$boxes.show({
        type: 'dropdown',
        activator: event.target,
        anchor: 'bottom-left',
        header: 'Фильтр',
        closeOnCLick: true,
        component: EvaMapFilter,
        componentProps: {
          state: this.state
        }
      });
    },
    showMapsourceViews(event) {
      this.$eva.$boxes.show({
        type: 'dropdown',
        activator: event.target,
        anchor: 'bottom-left',
        header: 'Быстрые переходы',
        closeOnCLick: true,
        component: EvaMapSourceViewList,
        componentProps: {
          readonly: !this.state.mapObjectId,
          state: this.state
        }
      });
    },
    showAlarmSign() {
      const showAlarmStatus = !this.state.showOnlyAlarm;
      this.state.showOnlyAlarm = showAlarmStatus;
      this.showAlarm = showAlarmStatus;
      this.$eva.$storages.local.set(`SHOW_ONLY_ALARM:${this.state.mapSourceId}`, showAlarmStatus)
    },
  }
}
</script>

<locale lang="ru">
{
  commands: {
    showLayers: {
      header: 'Слои'
    },
    showFilter: {
      header: 'Фильтр'
    },
    showMapsourceViews: {
      header: 'Быстрые переходы'
    },
    showAlarmSign: {
      header: 'Признак тревоги'
    },
  }
}
</locale>
