import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import evaApp from "@eva/client/app";

export default class LicensesEvaPlugin extends EvaPlugin {
  constructor(app) {
    super(app);
    this.activeLicenses = [];
    this.activeLicensesVendors = []; // для упрощения поиска лицензий
  }
  
  /**
   * Метод установки плагина, будет вызван при инциализации EvaApp
   */
  async install() {
    try {
      const licenses = await evaApp.$http.getItem(`/api/v1/softwarePackage/package/active`);
      this.setLicenses(licenses);
    } catch(err) {
      console.error(err);
      this.setLicenses([]);
    }
  }

  setLicenses(licenses) {
    this.activeLicenses = licenses;
    this.activeLicensesVendors = licenses.map(i => i.vendor_code);
  }

  /**
   * Метод для проверки наличия лицензий
   * @param {Array|String} licenses - массив или строка содержащий в себе vendore_code лицензии 
   * @returns 
   */
  checkLicenses(licenses) {
    if (licenses?.length) {
      const licensesArr = Array.isArray(licenses)
        ? licenses
        : [licenses];
  
      const result = licensesArr?.some((value) => {
        return this.activeLicensesVendors.includes(value);
      });
  
      return result;
    } else {
      return true;
    } 
  }
}
