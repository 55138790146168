
    import localesHash from '@eva/client/plugins/locales/LocalesHash';
    export default function (Component) {
      localesHash.push({ 
        lang: 'ru', 
        data: {
          [Component.options.name]: 
{
  commands: {
    gotoFirstPage: 'Первая страница',
    gotoPrevPage: 'Предыдущая страница',
    gotoNextPage: 'Следующая страница',
    gotoLastPage: 'Последняя страница',
    viewingSettings: 'Настройки просмотра',
  },
  settings: {
    columns: {
      expanding: {
        header: 'Расширить таблицу',
      }
    }
  }
}

        } 
      });
    }