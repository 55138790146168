<template>
  <div
    ref="mainContainer"
    class="eva-background-2"
    :class="$options.name"
    @mousemove="onComponentMove"
    @mouseup="onComponentUp"
    @keyup="onKeyup"
    tabindex="0"
  >
    <div v-show="showElements" class="eva-video-archive__left eva-border-right">
      <eva-select-date
        v-model="selectedDate"
        :max="Date.now()"
        transparent
        class="eva-margin--clear"
      />
      <ul class="eva-border-top">
        <li
          v-for="item in items"
          :key="item.id"
          class="eva-border-bottom eva-border-bottom-center"
          :class="{ 'eva-video-archive--selected': selectedItem === item }"
          @click="onMapClick(item)"
        >
          {{ `${item.name}` }}
        </li>
      </ul>
    </div>
    <div class="eva-video-archive__content">
      <eva-video-player
        ref="player"
        :source="source"
        :parent="$el"
        :isArchive="true"
        :isFullscreen="isFullscreen"
        @archiveClick="onOpenOnline"
        @playerClick="onPlayerClick"
        @toggleFullscreen="onToggleFullscreen"
      >
        <template v-slot:header>
          <eva-video-m-o-header
            :settings="settings"
            :isFullscreen="isFullscreen"
            :currentMonitoringObject="currentMonitoringObject"
            :mainContainerHeight="$refs?.mainContainer?.clientHeight"
            @setCurrentMO="setCurrentMonitoringObject"
          />
        </template>
      </eva-video-player>

      <div v-show="showElements" class="eva-video-archive__bottom">
        <canvas
          v-tooltip="onTooltip('Воспроизводимый фрагмент', 'current')"
          ref="currentCanvas"
          class="eva-video-archive__current"
          @mouseover="onShowTooltip('current')"
          @mouseout="onHideTooltip('current')"
          @mousedown="onCurrentDown"
        />
        <canvas
          v-tooltip="onTooltip('Выбранный диапозон', 'screen')"
          ref="screenCanvas"
          @mouseover="onShowTooltip('screen')"
          @mouseout="onHideTooltip('screen')"
          @mousedown="onScreenDown"
          @wheel="onComponentWheel"
        />
        <canvas
          v-tooltip="onTooltip('Архив за сутки', 'map')"
          ref="mapCanvas"
          class="eva-video-archive__map"
          @mouseover="onShowTooltip('map')"
          @mouseout="onHideTooltip('map')"
          @mousedown="onMapDown"
          @wheel="onComponentWheel"
        />

        <div class="eva-video-archive__range eva-foreground-1">
          {{ currentRange }}
        </div>

        <div class="eva-video-archive__duration">
          {{ duration }}
        </div>

        <div class="eva-video-archive__panel">
          <eva-btn
            icon="mdi-skip-backward"
            type="icon--secondary--small"
            @click="onPrev"
            tooltip="Предыдущая запись"
          />

          <eva-btn
            icon="mdi-step-backward"
            type="icon--secondary--small"
            @click="onBackward"
            tooltip="Перемотать кадр назад"
            :disabled="controlDisabled"
          />

          <eva-btn
            v-if="source && source.state.isPlaying"
            icon="mdi-pause"
            type="icon--secondary--small"
            @click="onPause"
            tooltip="Пауза"
            :disabled="controlDisabled"
          />
          <eva-btn
            v-else
            icon="mdi-play"
            type="icon--secondary--small"
            @click="onPlay"
            tooltip="Воспроизведение"
            :disabled="controlDisabled"
          />

          <eva-btn
            icon="mdi-step-forward"
            type="icon--secondary--small"
            @click="onForward"
            tooltip="Перемотать кадр вперед"
            :disabled="controlDisabled"
          />

          <eva-btn
            icon="mdi-skip-forward"
            type="icon--secondary--small"
            @click="onNext"
            tooltip="Следующая запись"
          />

          <eva-select
            v-model="currentSpeed"
            :items="speedItems"
            hide-icon
            transparent
            style="width: 72px"
            :disabled="source && source.state.isError"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EvaVideoPlayer from "../EvaVideoPlayer.vue";
import EvaHlsVideoSource from "../sources/EvaHlsVideoSource";
import EvaVideoMOHeader from "./EvaVideoMOHeader.vue";
import EvaTextbox from "@eva/client/plugins/core/components/inputs/EvaTextbox.vue";
import moment from "moment";
import { isEqual } from "lodash";
import checkLicense from "./_common/licenses";
import { ref } from "vue";

const SelectedElemMarkerHeight = 10; // высота указателя текущего момента
const SelectedElemMarkerWidth = 16; // ширина указателя текущего момента
const TimeMarkerWidth = 2; // ширина метки времени
const TimeMarkerHeight = 7; // высота метки времени
const TimeMarkerTextHeight = 7; // смещение текста метки времени
const TimeMarkerTextWidth = 70; // ширина текста метки времени
const TimeMarkerStep = 4; // шаг часов для меток времени

const MapCanvasHeight = 34;
const ScreenCanvasHeight = 34;
const CurrentCanvasHeight = 16;

class Line {
  constructor(id = -1, x = 0, w = 0) {
    this.id = id;
    this.x = x;
    this.w = w;
    this.scale = 1;
  }

  convertTo(...lines) {
    let x = this.x;
    let w = this.w;
    for (let i = 0; i < lines.length; i++) {
      let scale = i === 0 ? 1 : lines[i - 1].w / lines[i].w;
      x = (x - lines[i].x) / scale;
      w = w / scale;
    }
    return { x, w };
  }
}

function calcCoords(items, width, height, x, w) {
  let world = new Line();
  let map = new Line();
  let screen = new Line();
  let screen2 = new Line();

  items = items || [];

  if (items.length) {
    world.x = items[0].start;
    world.w = items[items.length - 1].end - items[0].start;

    map.w = width;

    screen.x = world.x + x;
    screen.w = w;

    let t = toMap(screen);

    screen2.x = t.x;
    screen2.w = (width * width) / t.w;

    items = items.map(({ id, start, end }) => {
      return new Line(id, start, end - start);
    });
  }

  function toMap(item) {
    return item.convertTo(world, map);
  }

  function fromMap(x) {
    return (world.w / width) * x;
  }

  function fromScreen(x) {
    return (screen2.w / width) * x + world.x + screen2.x;
  }

  function toScreen(item) {
    return item.convertTo(world, map, screen2);
  }

  return {
    world,
    map,
    screen,
    items,
    fromMap,
    toMap,
    toScreen,
    fromScreen,
  };
}

export default {
  name: "eva-video-archive2",

  components: {
    EvaVideoMOHeader,
    EvaTextbox,
    EvaVideoPlayer,
  },
  props: {
    box: {
      type: Object,
    },
    event: {
      type: Object,
    },
    settings: {
      type: Object,
    },
    showElements: {
      type: Boolean,
      default: true,
    },
    isFullscreen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      haveLicenses: ref(false),
      stream: null,
      currentMonitoringObject: null,
      source: null,
      selectedDate: null,
      items: [],
      selectedItem: null,
      loadMapInterval: null,
      tooltips: {
        current: false,
        screen: false,
        map: false,
      },
      x: 0,
      w: 30 * 60 * 1000,
      speedItems: [
        {
          id: 0.25,
          name: "x 0.25",
        },
        {
          id: 0.5,
          name: "x 0.5",
        },
        {
          id: 0.75,
          name: "x 0.75",
        },
        {
          id: 1,
          name: "x 1",
        },
        {
          id: 1.25,
          name: "x 1.25",
        },
        {
          id: 1.5,
          name: "x 1.5",
        },
        {
          id: 1.75,
          name: "x 1.75",
        },
        {
          id: 2,
          name: "x 2",
        },
      ],
      currentSpeed: {
        id: 1,
        name: "x 1",
      },
      isPaused: false,
      duration: "",
      archiveStartTime: null,
      firstOpen: true,
    };
  },
  computed: {
    currentRange() {
      return moment(new Date(this.w)).utc().format("HH:mm:ss");
    },
    controlDisabled() {
      return (
        !this.source ||
        this.source?.state?.isLoading ||
        this.source?.state?.isError
      );
    },
  },

  watch: {
    showElements() {
      this.$nextTick(() => {
        this.drawTimeline();
      });
    },
    async stream() {
      await this.load();
    },
    async selectedDate() {
      await this.load();
    },
    selectedItem() {
      this.drawTimeline();
    },
    currentSpeed(value) {
      if (this.source) {
        this.$refs.player.$refs.video.playbackRate = parseFloat(value.id);
      }
    },
    "source.state.isPlaying"(value) {
      if (value) {
        this.$refs.player.$refs.video.playbackRate = parseFloat(
          this.currentSpeed ? this.currentSpeed.id : 1
        );
      }
    },
    async "settings.archive"() {
      await this.setArchiveTime();
      this.firstOpen = true;
      this.onMapClick(this.findClosestItem());
    },
  },

  methods: {
    async setArchiveTime() {
      if (this.settings.archiveStartTime) {
        const timeZone = new Date().getTimezoneOffset() * 60000;
        if (timeZone > 0) {
          this.archiveStartTime = this.settings.archiveStartTime - timeZone;
        } else {
          this.archiveStartTime =
            this.settings.archiveStartTime + Math.abs(timeZone);
        }
        this.selectedDate = this.archiveStartTime;
      } else {
        this.selectedDate = new Date().getTime();
      }
    },
    findClosestItem() {
      let selectedItem = null;
      if (this.firstOpen && this.archiveStartTime) {
        const archiveStartTime = this.archiveStartTime;
        selectedItem = this.items.reduce(function (prev, curr) {
          return Math.abs(curr.start - archiveStartTime) <
            Math.abs(prev.start - archiveStartTime)
            ? curr
            : prev;
        }, this.items[0]);
      } else {
        selectedItem = this.items[0];
      }
      return selectedItem;
    },
    updateCanvasHeight() {
      this.$refs.currentCanvas.height = CurrentCanvasHeight;
      this.$refs.mapCanvas.height = MapCanvasHeight;
      this.$refs.screenCanvas.height = ScreenCanvasHeight;
    },
    onPlayerClick() {
      this.$emit("playerClick");
    },
    onOpenOnline() {
      this.$emit("openOnline");
    },
    async loadMap() {
      if (this.haveLicenses) {
        const date = this.$eva.$tools.formatDate(
          this.selectedDate,
          "DD-MM-YYYY"
        );
        const { id, stream_map } = await this.$eva.$http.post(
          `/api/v1/videohubservice/integrationapi/map/${this.stream}`,
          {
            date_from: date,
            date_to: date,
          }
        );
        if (isEqual(this.items, stream_map)) {
          return false;
        }
        let selectedItemIndex = this.items.indexOf(this.selectedItem);
        this.items = stream_map;

        await this.onMapClick(this.items[selectedItemIndex]);

        return true;
      }
    },
    async load() {
      this.source = null;
      this.items = [];
      this.selectedItem = null;
      this.drawTimeline();
      if (this.stream && this.selectedDate && this.haveLicenses) {
        const isUpdated = await this.loadMap();
        if (this.selectedItem && !isUpdated) {
          return;
        }

        const selectedItem = this.findClosestItem();
        this.$nextTick(() => {
          this.onMapClick(selectedItem);
          this.drawTimeline();
        });
      }
    },
    onPrev() {
      if (this.source) {
        if (this.selectedItem) {
          let index = this.items.indexOf(this.selectedItem);
          if (index > 0) {
            this.onMapClick(this.items[index - 1]);
          } else {
            this.onMapClick(this.items[this.items.length - 1]);
          }
        } else {
          this.onMapClick(this.items[0]);
        }
      }
    },
    onNext() {
      if (this.source) {
        if (this.selectedItem) {
          let index = this.items.indexOf(this.selectedItem);
          if (index >= 0 && index < this.items.length - 1) {
            this.onMapClick(this.items[index + 1]);
          } else if (index === this.items.length - 1) {
            this.onMapClick(this.items[0]);
          }
        } else {
          this.onMapClick(this.items[0]);
        }
      }
    },
    onBackward() {
      if (!this.controlDisabled) {
        this.onPause();
        this.$refs.player.$refs.video.currentTime -=
          (this.currentSpeed && this.currentSpeed.id) || 1;
      }
    },
    onForward() {
      if (!this.controlDisabled) {
        this.onPause();
        this.$refs.player.$refs.video.currentTime +=
          (this.currentSpeed && this.currentSpeed.id) || 1;
      }
    },
    onPlay() {
      if (!this.controlDisabled) {
        this.source.play(false);
        this.isPaused = false;
      }
    },
    onPause() {
      if (!this.controlDisabled) {
        this.source.pause();
        this.isPaused = true;
      }
    },
    async onMapClick(item) {
      if (!item) {
        return;
      }
      const { begin_time, end_time, timelength } = item;
      this.selectedItem = item;
      this.firstOpen = false;
      if (this.source) {
        await this.source.updateSource(
          {
            stream_id: this.stream,
            begin_time,
            end_time,
            timeout: timelength / 100,
          },
          this.isPaused
        );
      } else {
        this.$nextTick(() => {
          this.source = new EvaHlsVideoSource(
            {
              stream_id: this.stream,
              begin_time,
              end_time,
              timeout: timelength / 100,
            },
            this.isPaused,
            this.settings
          );
        });
      }
    },
    onComponentWheel(e) {
      if (this.w != null) {
        if (e.deltaY < 0) {
          this.w *= 0.95;
        } else {
          this.w *= 1.05;
        }

        const width = this.$refs.mapCanvas.offsetWidth;
        const height = this.$refs.mapCanvas.offsetHeight;
        const { world } = calcCoords(this.items, width, height, this.x, this.w);

        if (this.x + this.w > world.w) {
          this.w = world.w - this.x;
        }
        this.drawTimeline();
      }
    },
    onMapDown(e) {
      const width = this.$refs.mapCanvas.offsetWidth;
      const height = this.$refs.mapCanvas.offsetHeight;
      const res = calcCoords(this.items, width, height, this.x, this.w);
      this.x = res.fromMap(e.offsetX) - this.w / 2;
      if (this.x < 0) {
        this.x = 0;
      }
      this.drawTimeline();

      this.prev = e.offsetX;
    },
    onComponentUp(e) {
      this.prev = null;
    },
    onComponentMove(e) {
      if (this.prev != null) {
        const width = this.$refs.mapCanvas.offsetWidth;
        const height = this.$refs.mapCanvas.offsetHeight;
        const res = calcCoords(this.items, width, height, this.x, this.w);
        const curX = e.clientX - this.$refs.mapCanvas.getBoundingClientRect().x;
        let dx = res.fromMap(curX - this.prev);
        this.x += dx;
        if (this.x < 0) {
          this.x = 0;
        } else if (this.x + this.w > res.world.w) {
          this.x = res.world.w - this.w;
        }
        this.drawTimeline();
        this.prev = curX;
      }
    },
    onScreenDown(e) {
      const width = this.$refs.mapCanvas.offsetWidth;
      const height = this.$refs.mapCanvas.offsetHeight;
      const res = calcCoords(this.items, width, height, this.x, this.w);
      let item = res.items.find((i) => {
        let { x, w } = res.toScreen(i);
        if (e.offsetX >= x && e.offsetX <= x + w) {
          return i;
        }
      });
      if (item) {
        let i = this.items.find((ii) => item.id === ii.id);
        this.onMapClick(i);
      }
    },
    onCurrentDown(e) {
      if (!this.selectedItem) {
        return;
      }

      /*
        Эта константа используется,
        так как с сервера не приходят видео-данные по последней секунде
        и мы никогда не можем дождаться окончания видео.
      */
      const DUMMY_DELTA = 1; //1 секунда

      const width = this.$refs.currentCanvas.offsetWidth;
      const cost = width / (this.selectedItem.timelength / 1000 - DUMMY_DELTA);
      const currentTime = e.offsetX / cost;
      if (currentTime <= this.$refs.player.$refs.video.duration) {
        this.$refs.player.$refs.video.currentTime = currentTime;
      } else {
        //TODO Обработка клика по незагруженной области
      }
    },
    onKeyup(e) {
      if (this.source && !this.source.state.isPlaying) {
        switch (e.code) {
          case "ArrowLeft":
            this.onBackward();
            break;
          case "ArrowRight":
            this.onForward();
            break;
        }
      }
    },
    onTooltip(content, tooltipName) {
      return {
        ...this.$eva.$tools.tooltipContent(content),
        shown: this.tooltips[tooltipName],
        triggers: ["manual"],
      };
    },
    onShowTooltip(tooltipName) {
      this.tooltips[tooltipName] = true;
      let self = this;
      setTimeout(() => {
        self.tooltips[tooltipName] = false;
      }, 2000);
    },
    onHideTooltip(tooltipName) {
      this.tooltips[tooltipName] = false;
    },
    withCtx(canvas, handler) {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      const ctx = canvas.getContext("2d");
      handler(ctx, canvas.width, canvas.height);
    },
    drawSelectedElem(ctx, res, i, height) {
      ctx.fillStyle = "#0260CF";
      let { x, w } = res.toMap(res.items[i]);
      ctx.fillRect(x, 0, w, height);

      // Draw triangle indicator below the selected item
      ctx.beginPath();
      ctx.moveTo(x + w / 2, height);
      ctx.lineTo(
        x + w / 2 - SelectedElemMarkerWidth / 2,
        height + SelectedElemMarkerHeight
      );
      ctx.lineTo(
        x + w / 2 + SelectedElemMarkerWidth / 2,
        height + SelectedElemMarkerHeight
      );
      ctx.closePath();
      ctx.fill();
    },
    getMarkersLines(additionalTimePoints) {
      return additionalTimePoints.map(({ id, time }) => {
        return new Line(id, time.valueOf(), TimeMarkerWidth);
      });
    },
    drawTimeMarker(ctx, timeText, x, height, isEdge) {
      ctx.fillText(timeText, x, height - TimeMarkerTextHeight);
      if (!isEdge) {
        ctx.fillRect(x, height, TimeMarkerWidth, TimeMarkerHeight);
      }
    },
    isValidTimeMarkerDistance(x, res, points, i) {
      return (
        x - res.toMap(points[i - 1]).x > TimeMarkerTextWidth &&
        res.toMap(points[i + 1]).x - x > TimeMarkerTextWidth
      );
    },
    drawTimeMarkers(ctx, res, height) {
      ctx.fillStyle = "white";
      ctx.font = window.getComputedStyle(document.body).font;

      ctx.textAlign = "left";
      const startMarker = moment(new Date(this.items[0].start)).utc();
      this.drawTimeMarker(ctx, startMarker.format("HH:mm:ss"), 0, height, true);

      ctx.textAlign = "right";
      const endMarker = moment(
        new Date(this.items[this.items.length - 1].end)
      ).utc();
      let { x } = res.toMap(res.items[res.items.length - 1]);
      this.drawTimeMarker(ctx, endMarker.format("HH:mm:ss"), x, height, true);

      ctx.textAlign = "center";
      const additionalMarkers = [{ id: -1, time: startMarker }];
      for (let i = 0; i <= 24; i += TimeMarkerStep) {
        const timeMarker = moment(startMarker).set({
          hour: i,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        if (
          startMarker.hour() < timeMarker.hour() &&
          timeMarker.hour() < endMarker.hour()
        ) {
          additionalMarkers.push({ id: i, time: timeMarker });
        }
      }
      additionalMarkers.push({ id: -1, time: endMarker });

      const points = this.getMarkersLines(additionalMarkers);
      for (let i = 1; i < points.length - 1; i++) {
        const { x } = res.toMap(points[i]);
        if (this.isValidTimeMarkerDistance(x, res, points, i)) {
          this.drawTimeMarker(
            ctx,
            additionalMarkers[i].time.format("HH:mm"),
            x,
            height
          );
        }
      }
    },
    drawTimeline() {
      this.updateCanvasHeight();
      let res = null;
      this.withCtx(this.$refs.mapCanvas, (ctx, width, height) => {
        height = height - SelectedElemMarkerHeight;
        res = calcCoords(this.items, width, height, this.x, this.w);

        ctx.globalAlpha = 1;
        ctx.strokeStyle = null;
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, width, height);

        for (let i = 0; i < this.items.length; i++) {
          if (this.selectedItem && this.selectedItem.id === this.items[i].id) {
            this.drawSelectedElem(ctx, res, i, height);
          } else {
            ctx.fillStyle = "#495A6F";
            let { x, w } = res.toMap(res.items[i]);
            ctx.fillRect(x, 0, w, height);
          }
        }

        if (this.items && this.items.length) {
          this.drawTimeMarkers(ctx, res, height);
        }

        ctx.lineWidth = 3;
        ctx.strokeStyle = "#009688";
        ctx.fillStyle = "#009688";

        let { x, w } = res.toMap(res.screen);

        ctx.strokeRect(x, 1, w, height - 2);

        ctx.globalAlpha = 0.1;
        ctx.fillRect(x, 0, w, height);
      });

      this.withCtx(this.$refs.screenCanvas, (ctx, width, height) => {
        ctx.globalAlpha = 1;
        ctx.strokeStyle = null;
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, width, height);

        for (let i = 0; i < this.items.length; i++) {
          ctx.fillStyle =
            this.selectedItem && this.selectedItem.id === this.items[i].id
              ? "#0260CF"
              : "#495A6F";
          let { x, w } = res.toScreen(res.items[i]);

          ctx.fillRect(x, 0, w, height);
        }
      });

      this.drawTimelineCurrent();
    },
    drawTimelineCurrent() {
      /*
        Эта константа используется,
        так как с сервера не приходят видео-данные по последней секунде
        и мы никогда не можем дождаться окончания видео.
      */
      const DUMMY_DELTA = 1; //1 секунда

      const canvas = this.$refs.currentCanvas;
      const width = (canvas.width = canvas.offsetWidth);
      const height = (canvas.height = canvas.offsetHeight);
      const ctx = canvas.getContext("2d");

      if (!(this.$refs.player && this.$refs.player.$refs.video)) {
        ctx.fillStyle = "#EEEEEE";
        ctx.fillRect(0, 0, width, height);
        return;
      }

      const current = this.$refs.player.$refs.video.currentTime || 0;
      const loaded = this.$refs.player.$refs.video.duration || 0;
      const duration = this.selectedItem
        ? (this.selectedItem.end - this.selectedItem.start) / 1000 - DUMMY_DELTA
        : 0;
      const currentStr =
        this.selectedItem &&
        moment(new Date(this.selectedItem.start + current * 1000))
          .utc()
          .format("HH:mm:ss");
      const durationStr =
        this.selectedItem &&
        moment(new Date(this.selectedItem.start + duration * 1000))
          .utc()
          .format("HH:mm:ss");
      this.duration =
        (currentStr && durationStr && `${currentStr} / ${durationStr}`) || "";

      ctx.fillStyle = "#EEEEEE";
      ctx.fillRect(0, 0, width, height);

      if (loaded > 0) {
        const cost = width / duration;
        const w = cost * loaded;
        ctx.fillStyle = "#BDBDBD";
        ctx.fillRect(0, 0, w, height);
      }

      if (duration > 0) {
        const cost = width / duration;
        const w = cost * current;
        ctx.fillStyle = "#757575";
        ctx.fillRect(0, 0, w, height);
      }

      if (
        currentStr != null &&
        durationStr != null &&
        currentStr === durationStr &&
        !this.isPaused
      ) {
        this.onNext();
      }
    },
    async setCurrentMonitoringObject(monitoringObject) {
      if (this.currentMonitoringObject?.id !== monitoringObject.id) {
        this.currentMonitoringObject = monitoringObject;
        this.settings.monitoringObject = this.currentMonitoringObject;
        this.stream = this.currentMonitoringObject.ref_video_stream.id;
        await this.load();
      }
    },
    onToggleFullscreen() {
      this.$emit("toggleFullscreen");
    },
  },

  async mounted() {
    this.updateCanvasHeight();
    this.setCurrentMonitoringObject(this.settings.monitoringObject);
    this.haveLicenses = await checkLicense(this.stream);
    if (!this.haveLicenses) {
      this.$eva.$boxes.notifyError(
        this.$eva.$t(`$t.${this.$options.name}.errors.license`)
      );
    }
    this.onWindowResize = () => this.drawTimeline();
    window.addEventListener("resize", this.onWindowResize);

    this.onDuration = () => this.drawTimelineCurrent();
    this.$refs.player.$refs.video.addEventListener(
      "timeupdate",
      this.onDuration
    );
    this.$refs.player.$refs.video.addEventListener(
      "durationchange",
      this.onDuration
    );

    this.setArchiveTime();
    this.$nextTick(() => this.$el.focus());
    this.loadMapInterval = setInterval(async () => {
      await this.loadMap();
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.loadMapInterval);
    if (this.source) {
      this.source.clear();
    }
    window.removeEventListener("resize", this.onWindowResize);
    this.$refs.player.$refs.video.removeEventListener(
      "timeupdate",
      this.onDuration
    );
    this.$refs.player.$refs.video.removeEventListener(
      "durationchange",
      this.onDuration
    );
  },
};
</script>

<style lang="less">
.eva-video-archive2 {
  width: 100%;
  height: 100%;
  display: flex;

  * {
    user-select: none;
  }

  .eva-video-archive__left {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 100%;
    min-height: 0;
    user-select: none;
    .eva-select-date {
      height: @eva-header;
      flex-shrink: 0;
      .eva-textbox__inner {
        display: flex;
        align-items: center;
      }
    }
    .eva-border-bottom-center {
      justify-content: center;
    }
    ul {
      overflow-y: auto;
      list-style-type: none;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 2px 16px;
        height: 32px;

        &:hover {
          opacity: 0.7;
        }

        &::marker {
          display: none;
        }

        &.eva-video-archive--selected {
          border: 2px solid #0260cf !important;
        }
      }
    }
  }
  .eva-video-archive__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    .eva-video-player {
    }
    .eva-video-archive__bottom {
      padding: 6px;
      height: 144px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      position: relative;
      canvas {
        width: 100%;
        height: 24px;
        cursor: pointer;
      }
      .eva-video-archive__current {
        height: 16px;
      }
      .eva-video-archive__map {
        height: 34px;
      }
      .eva-video-archive__range {
        position: absolute;
        top: 30px;
        display: flex;
        align-items: center;
        user-select: none;
        pointer-events: none;
      }

      .eva-video-archive__duration {
        position: absolute;
        top: 4px;
        left: 10px;
        user-select: none;
        pointer-events: none;
        color: #000000;
        font-weight: 600;
      }
      .eva-video-archive__panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: -10px;
        height: 100%;
        width: 100%;
        gap: @eva-padding;
      }
    }
  }
}
</style>

<locale lang="ru">
  {
    errors: {
      license: 'Раздел недоступен. Требуется дополнительная лицензия'
    },
  }
</locale>
