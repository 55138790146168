import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import EvaCommandList from "./components/EvaCommandList";
import { computed, ref, isRef, watch } from 'vue';
import EvaAppError from "@eva/client/plugins/errors/EvaAppError";

const DEFAULT_COMMANDS = {
  ok: {
    color: 'info',
    type: 'text--small',
    icon: 'mdi-check-bold',
    prefix: 'core.dialog.commands'
  },
  cancel: {
    type: 'icon--secondary--small',
    color: 'default',
    icon: 'mdi-close',
    prefix: 'core.dialog.commands'
  },
  yes: {
    color: 'info',
    type: 'icon-text--small',
    icon: 'mdi-check-bold',
    prefix: 'core.dialog.commands'
  },
  no: {
    color: 'default',
    type: 'icon-text--secondary--small',
    icon: 'mdi-cancel',
    prefix: 'core.dialog.commands'
  },
  /*add: {
    icon: 'mdi-plus',
    color: 'success',
    success: true,
    error: true
  },*/
  add: {
    type: 'icon-text',
    color: 'primary',
    icon: 'mdi-plus',
    success: true,
    error: true
  },
  edit: {
    icon: 'mdi-pencil-outline',
    type: 'icon--flat--small',
    /*color: 'grey',*/
    visible: false,
    success: true,
    error: true,
    click: true
  },
  copy: {
    icon: 'mdi-content-copy',
    type: 'icon--flat--small',
    /*color: 'grey',*/
    success: true,
    error: true
  },
  remove: {
    icon: 'mdi-trash-can-outline',
    type: 'icon--flat--small',
    /*color: 'grey',*/
    success: true,
    error: true,
    confirm: true
  },
  filter: {
    icon: 'mdi-filter-variant',
    type: 'icon--secondary--flat',
    prefix: 'core.tables.commands',
    success: false,
    error: false,
    confirm: false
  },
  download: {
    type: 'icon-text--secondary',
    icon: 'mdi-download',
    success: true,
    error: true,
  },
  send: {
    type: 'icon-text--secondary',
    icon: 'mdi-email-fast-outline',
    success: true,
    error: true
  }
}

class CommandsEvaPlugin extends EvaPlugin {

  constructor(app) {
    super(app);
  }

  install() {
    this.app.components(
      EvaCommandList
    );
  }

  create(command, commandName = undefined) {
    if (!command) {
      return undefined;
    }

    if (this.isCommand(command)) {
      return command;
    }

    command = this.normalizeCommand(command, commandName);

    const name = computed(() => this.app.$tools.resolveValue(command.name));
    const prefix = computed(() => this.app.$tools.resolveValue(command.prefix));
    const label = computed(() => {
      if (typeof command.label === 'function') {
        return this.app.$tools.resolveValue(command.label);
      } 
      return command.label || (!prefix.value ? '' : `$t.${prefix.value}.${name.value}.header`)
    });
    const tooltip = computed(() => `$t.${prefix.value}.${name.value}.tooltip`);
    const confirm = computed(() => `$t.${prefix.value}.${name.value}.confirm`);
    const notifySuccess = computed(() => `$t.${prefix.value}.${name.value}.success`);
    const icon = computed(() => this.app.$tools.resolveValue(command.icon));
    const color = computed(() => this.app.$tools.resolveValue(command.color));
    const click = computed(() => this.app.$tools.resolveValue(command.click));
    const type = computed(() => this.app.$tools.resolveValue(command.type));
    const action = computed(() => this.app.$tools.resolveValue(command.action));
    const commandBtn = computed(() => command.commandBtn);
    const visible = typeof command?.visible === 'function'
      ? (arg) => command?.visible(arg)
      : computed(() => {
          if (isRef(command.visible)) {
            return command.visible.value;
          }
          let result = this.app.$tools.resolveValue(command.visible);
          return result == null || result;
        }
      );
    const disabled = typeof command.disabled === 'function'
      ? computed(() => this.app.$tools.resolveValue(command.disabled))
      : ref(!!command.disabled);
    const badge = ref(false);
    const commands = command.commands;

    const app = this.app;

    function showError(error) {
      if (command.error) {
        const usr_msg = error.response?.data?.usr_msg;
        if (usr_msg) {
          app.$boxes.notifyError(usr_msg);
        } else if (error instanceof EvaAppError) {
          app.$boxes.notifyError(error.code);
        } else {
          app.$boxes.notifyError(`$t.${prefix.value}.${name.value}.error`);
        }
      }
    }

    async function execute(...args) {
      let commandResult = {
        name: name.value
      }

      try {
        if (command.confirm && ! await app.$boxes.confirm({ message: confirm.value, messageArgs: command.confirm })) {
          commandResult.error = 'cancel';
          return commandResult;
        }
        if (command.handle) {
          commandResult.result = await command.handle(...args);
        }
        if (command.success && (
          commandResult.result !== false &&
          commandResult.result !== 'cancel' &&
          commandResult.result !== 'no'
        )) {
          app.$boxes.notifySuccess(notifySuccess.value);
        }
      } catch(error) {
        commandResult.error = error;
        app.$logs.error(
          'CommandsEvaPlugin',
          `Во время выполнения команды '${prefix.value}.${name.value}' произошла ошибка.`,
          error
        );
        showError(error);
      }
      return commandResult;
    }

    return {
      name,
      prefix,
      label,
      tooltip,
      icon,
      color,
      disabled,
      badge,
      visible,
      click,
      commands,
      type,
      action,
      commandBtn,
      execute,
      showError
    };
  }

  createList(target) {
    if (target) {
      return this.app.$tools
        .mapObjectOrArray(target, (command, name) => command !== false && this.create(command, name))
        .filter((command) => !!command);
    } else {
      return [];
    }
  }

  isCommand(command) {
    return (typeof command === 'object') && (typeof command.execute === 'function');
  }

  normalizeCommand(command, name = undefined) {
    if (command === false) {
      return null;
    }
    if (command === true) {
      command = {
        name
      };
    } else if (typeof command === 'function') {
      command = {
        name,
        handle: command
      };
    }
    let defaultCommand = DEFAULT_COMMANDS[command.name];
    if (defaultCommand) {
      return Object.assign({}, defaultCommand, command);
    } else {
      return Object.assign({}, command);
    }
  }
}

export default CommandsEvaPlugin;
