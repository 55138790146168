<template>
  <div v-if="settings" class="eva-form-grid-layout" :class="classes" :style="gridStyle">

    <div
        v-for="cell in gridCells"
        class="eva-form-grid-layout__cell"
        :style="cell.style"
        @dragover="onDragOver"
        @drop="onDrop($event, cell)"
    >
    </div>

    <component
      v-for="(item, itemIndex) in settings.layouts"
      :key="itemIndex"
      :is="item.component"
      v-bind="item.componentProps"
      :design-mode="designMode"
    />

    <template
      v-for="(item, itemIndex) in settings.fields"
    >
      <component
        v-show="$eva.$tools.resolveValue(item.settings.visible, item.model) !== false"
        :key="itemIndex"
        :class="{ 'eva-selected-input' : selectedName && selectedName === item.settings.name }"
        :form-settings="formSettings"
        :is="item.component"
        :settings="item.settings"
        :model="item.model"
        :style="item.style"
        :validators="item.validators"
        :draggable="designMode"
        :design-mode="designMode"
        @dragstart.native="onDragStart($event, item)"
        @click="$emit('selected-name', item.settings.name)"
        @click.native="$emit('selected-name', item.settings.name)"
      />
    </template>

  </div>
</template>

<script>
export default {
  name: 'eva-form-grid-layout',

  props: {
    formSettings: {},
    settings: {},
    designMode: {
      type: Boolean
    },
    selectedName: {
      type: String
    }
  },

  data() {
    return {
      drag: {
        ['eva-translate-form-field']: true
      }
    }
  },

  computed: {
    classes() {
      return {
        'eva-form-layout--border': this.settings && this.settings.border
      }
    },
    gridCells() {
      if (this.designMode && this.settings.w && this.settings.h) {
        let w = this.settings.w;
        if (w < 1) {
          w = 1;
        } else if (w > 12) {
          w = 12;
        }
        let h = this.settings.h;
        if (h < 1) {
          h = 1;
        } else if (h > 100) {
          h = 100;
        }
        let cells = [];
        for (let i = 0; i < h; i++) {
          for (let j = 0; j < w; j++) {
            let style = {
              gridArea: `${i + 1} / ${j + 1} / ${i + 2} / ${j + 2}`,
              backgroundColor: null
            };
            cells.push({
              style,
              x: j,
              y: i,
              content: `${i + 1} : ${j + 1}`
            });
          }
        }
        return cells;
      }
    },
    gridStyle() {
      let w = this.settings.w;
      if (typeof w === 'number') {
        if (w < 1) {
          w = 1;
        } else if (w > 12) {
          w = 12;
        }
      }
      let h = this.settings.h;
      if (typeof h === 'number') {
        if (h < 1) {
          h = 1;
        } else if (h > 100) {
          h = 100;
        }
      }
      let result = {
        /*'grid-template-rows': h == null
          ? undefined
            : `repeat(${h}, calc((100% - ${(h - 1) * 20}px) / ${h}))`,*/
        'grid-template-columns': w == null
          ? undefined
          : `repeat(${w}, ${100 / w}%)`
      };
      if (this.settings.margin) {
        result.margin = this.settings.margin;
      }
      if (this.settings.position) {
        let p = this.settings.position;
        result.gridArea = `${p.y + 1} / ${p.x + 1} / ${p.y + 1 + p.h} / ${p.x + 1 + p.w}`;
      }
      if (this.settings.height) {
        result.height = this.settings.height;
      }
      if (this.designMode) {
        //result.backgroundColor = 'green'
      }
      return result;
    }
  },

  methods: {
    onDragStart(e, item) {
      if (!this.designMode) {
        return;
      }
      this.$eva.$dragndrops.beginDragMove(e, this.drag, {
        name: item.settings.name
      });
    },
    onDragOver(e) {
      this.$eva.$dragndrops.allowDrop(e, this.drag);
    },
    async onDrop(e, { x, y }) {
      let res = this.$eva.$dragndrops.endDrop(e, this.drag);
      this.$emit('drop-field', {
        name: res['eva-translate-form-field'].name,
        x,
        y
      });
    }
  }
}
</script>

<style lang="less">
.eva-form-grid-layout {
  display: grid;
  > * {
    margin: @eva-padding!important;
  }
  .eva-input {
    margin: 0;
  }
  .eva-form-grid-layout__item {

  }
  &.eva-form-layout--border {
    /*padding: @eva-padding;*/
  }
  .eva-form-grid-layout__cell {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.2);
    outline: 1px #90A4AE dashed;
    min-height: 28px;
  }
  .eva-selected-input {
    outline: 2px solid #0260CF;
    box-sizing: border-box;
  }
}
</style>
