<template>
  <div
      class="eva-checkbox-cell"
      :class="{ 'eva-text-clipped': isClipped }"
  >
    {{ getValue }}
  </div>
</template>

<script>
import EvaTableCellMixin from "./EvaTableCellMixin";

export default {
  name: 'eva-checkbox-cell',

  mixins: [
    EvaTableCellMixin
  ],
  computed: {
    getValue() {
      if (!!this.column.checkbox_value) { 
        return this.currentValue === true 
          ? this.column.checkbox_value.positive
          : this.column.checkbox_value.negative;
      }
      if (this.currentValue === null) {
        return '';
      }

      return this.currentValue
        ? this.$eva.$t('$t.core.dialog.commands.yes.header')
        : this.$eva.$t('$t.core.dialog.commands.no.header');
    }
  }
}
</script>

<style lang="less">
.eva-checkbox-cell {
}
</style>
